import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const parts = value.split(this.DELIMITER);
    return {
      day: parseInt(parts[0], 10),
      month: parseInt(parts[1], 10),
      year: parseInt(parts[2], 10),
    };
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.pad(date.day)}${this.DELIMITER}${this.pad(date.month)}${
          this.DELIMITER
        }${date.year}`
      : '';
  }

  private pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }
}
