import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { Observable, catchError, map, tap } from 'rxjs';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { ErrorHandler } from '../helpers/error-handler';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler
  ) {}

  userEmail: string = null;
  createNewPassword: boolean = false;
  allUsers: {
    _id: string;
    name: string;
    email: string;
    role: { shortCode: string };
  }[] = [];

  /**
   * Service Function to change the user password.
   * @param { Object } body
   * @returns { Observable<CommonResponse<Object>> }
   */
  changePassword(body: Object): Observable<CommonResponse<Object>> {
    return this.apiService
      .patch<CommonResponse<Object>>('change-password', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Service to generate OTP for forgot password.
   * @param { Object } body
   * @returns { Observable<CommonResponse<Object>> }
   */
  generateOTP(body: { email: string }): Observable<CommonResponse<Object>> {
    this.userEmail = body.email;
    return this.apiService
      .post<CommonResponse<Object>>('otp', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Service to resend OTP.
   * @param { Object } body
   * @returns { Observable<CommonResponse<Object>> }
   */
  resendOTP(): Observable<CommonResponse<Object>> {
    const body = { email: this.userEmail };
    return this.apiService
      .post<CommonResponse<Object>>('resend-otp', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Service to verify OTP.
   * @param { Object } body
   * @returns { Observable<CommonResponse<Object>> }
   */
  verifyOTP(body: { otp: string }): Observable<CommonResponse<Object>> {
    const payload = { email: this.userEmail, otp: body.otp };
    return this.apiService
      .post<CommonResponse<Object>>('verify-otp', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Service to Reset Password.
   * @param { Object } body
   * @returns {Observable<CommonResponse<Object>>}
   */
  resetPassword(body: {
    newPassword: string;
  }): Observable<CommonResponse<Object>> {
    return this.apiService
      .patch<CommonResponse<Object>>('update-password', {
        email: this.userEmail,
        newPassword: body.newPassword,
      })
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  getAgents() {
    return this.apiService
      .post<CommonResponse<{ _id: string; name: string; email: string }[]>>(
        'advisers',
        {}
      )
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  getAllUsers() {
    return this.apiService
      .get<
        CommonResponse<
          {
            _id: string;
            name: string;
            email: string;
            role: { shortCode: string };
          }[]
        >
      >('users')
      .pipe(
        tap((response) => {
          this.allUsers = response?.data ?? [];
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  getTechnicalTeam() {
    return this.apiService
      .post<CommonResponse<{ _id: string; name: string; email: string }[]>>(
        'advisers/technical',
        {}
      )
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  assignToTechnicalMember(payload: Object) {
    return this.apiService
      .post<CommonResponse<null>>('advisers/assign-technical', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  assignToAgent(payload: Object) {
    return this.apiService
      .post<CommonResponse<null>>('advisers/assign-agent', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  changeAdvisor(payload: Object) {
    return this.apiService
      .post<CommonResponse<null>>('advisers/update-advisor', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  setUserEmail(email: string) {
    this.userEmail = email;
  }
}
