import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { BehaviorSubject, Observable, Subject, catchError, tap } from 'rxjs';

import { ErrorHandler } from '../helpers/error-handler';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { AuthenticatedUser } from '../../shared/models/authenticated-user.model';
import { LocalForageService } from './local-forage.service';
import { RoleModule } from '../../shared/models/role-module.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Represents the currently authenticated user.
   * @type {AuthenticatedUser}
   */
  user: AuthenticatedUser = null;

  /**
   * A BehaviorSubject for observing changes to the authenticated user.
   * @type {BehaviorSubject<AuthenticatedUser>}
   */
  user$: BehaviorSubject<AuthenticatedUser> =
    new BehaviorSubject<AuthenticatedUser>(null);

  /**
   * Subject which emit when the user is logged out
   */
  logout$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler,
    private localForageService: LocalForageService
  ) {}

  /**
   * Logs in the user with the provided payload.
   * @param {object} payload - The login payload containing user credentials.
   * @returns {Observable<CommonResponse<Object>>} An observable containing the login response.
   * @method
   */
  login(payload: object): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>('login', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Verifies the otp for two-factor authentication.
   * @param {object} payload - The login payload containing user credentials.
   * @returns {Observable<CommonResponse<{ user: AuthenticatedUser }>>} An observable containing the login response.
   * @method
   */
  verifyLogin(
    payload: Object
  ): Observable<CommonResponse<{ user: AuthenticatedUser }>> {
    return this.apiService
      .post<CommonResponse<{ user: AuthenticatedUser }>>(
        'verify-login',
        payload
      )
      .pipe(
        tap((response: CommonResponse<{ user: AuthenticatedUser }>) => {
          // If we get a success response we set the user info
          if (
            response?.data?.user &&
            response?.data?.user?.token &&
            !response?.data?.user?.forceChangePassword
          ) {
            this.setUserInfo(response.data?.user);
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * TO resend the login OTP.
   * @param payload
   * @returns
   */
  resendOTP(payload: Object): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>('login/resend', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Sets user information, stores it locally, and notifies subscribers.
   * @param {AuthenticatedUser} user - The user information to be set.
   * @method setUserInfo
   */
  setUserInfo(user: AuthenticatedUser) {
    this.user = user;
    this.localForageService.setItem('user', this.user);
    this.user$.next(this.user);
  }

  /**
   * @method autoLogin
   * @description Automatically logging in using the Session data
   */
  async autoLogin() {
    if (this.user && this.user?.token) {
      return;
    }
    const user: AuthenticatedUser = await this.localForageService.getItem(
      'user'
    );
    if (user) {
      this.user = user;
      this.user$.next(this.user);
    }
  }

  /**
   * @method getUserInfo
   * @description It will return the information of currently logged in user
   * @returns User
   */
  getUserInfo(): AuthenticatedUser {
    return this.user;
  }

  /**
   * @method logout
   * @description Clearing the user sessions and user data
   */
  logout() {
    this.localForageService.clear();
    this.user = null;
    this.user$.next(this.user);
    this.logout$.next();
  }

  /**
   * Function to check the permissions of the Current User.
   * @param { string } module
   * @param { string|any } permission
   * @returns
   */
  checkPermissions(module: String, permission: string) {
    if (!module) {
      return false;
    }

    if (!this.user) {
      return false;
    }

    let modules: RoleModule[] = [];
    modules = this.user.role.modules;

    const specificModule = modules.find((e) => e.moduleName === module);

    if (!specificModule) {
      return false;
    }

    if (permission === 'any') {
      return true;
    }

    const permissions = specificModule.permissions;
    const specificPermission = permissions.find((e) => e === permission);

    if (specificPermission) {
      return true;
    }

    return false;
  }

  /**
   * Function to get the permissions in a module for the current user
   * @param { string } moduleName
   * @returns { string[] }
   */
  getPermissions(moduleName: string): string[] {
    const specificModule = this.user.role.modules.find(
      (e) => e.moduleName === moduleName
    );
    const permissions = specificModule.permissions;
    return permissions;
  }
}
