import { MenuItem } from '../../shared/interfaces/menu-item.interface';
import { AuthService } from '../services/auth.service';

export function getMenu(authService: AuthService): MenuItem[] {
  const menu: MenuItem[] = [
    {
      label: 'Main',
      isTitle: true,
    },
    {
      label: 'Dashboard',
      icon: 'home',
      link: '/dashboard',
    },
    {
      label: 'Lists',
      isTitle: true,
    },
    {
      label: 'Quotations',
      icon: 'airplay',
      link: '/quotations',
      restricted: !authService.checkPermissions('Quotations', 'any'),
    },
    {
      label: 'Quick Quotes',
      icon: 'message-square',
      link: '/quick-quotes',
      restricted: !authService.checkPermissions('Quick Quotes', 'any'),
    },
    {
      label: 'Reports',
      icon: 'calendar',
      link: '/reports',
    },
  ];

  return menu;
}
