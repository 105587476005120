<nav class="navbar">
  <a
    href="javascript: void(0)"
    class="sidebar-toggler"
    (click)="toggleSidebar($event)"
  >
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <div class="search-form">
      <div class="input-group">
        <h4>Individual Medical Tool</h4>
      </div>
    </div>
    <!-- <form class="search-form">
      <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search"></i>
        </div>
        <input
          type="text"
          class="form-control"
          id="navbarForm"
          placeholder="Search here..."
        />
      </div>
    </form> -->
    <ul class="navbar-nav">
      <li class="nav-item nav-notifications dropdown" ngbDropdown>
        <a
          class="dropdown-toggle nav-link"
          id="notificationDropdown"
          aria-expanded="true"
          ngbDropdownToggle
        >
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator" *ngIf="notifications?.length > 0">
            <div class="circle"></div>
          </div>
          <div
            *ngIf="notifications?.length > 0"
            ngbDropdownMenu
            class="px-0 dropdown-menu"
            aria-labelledby="notificationDropdown"
          >
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>{{ notifications?.length }} New Notifications</p>
              <!-- <a href="javascript:void(0)" (click)="(false)" class="text-muted"
                >Clear all</a
              > -->
            </div>
            <div class="p-1">
              <a
                href="javascript:void()"
                (click)="notificationClick(notification)"
                class="dropdown-item d-flex align-items-center py-2"
                *ngFor="let notification of notifications"
              >
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="feather icon-at-sign icon-sm text-white"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p style="max-width: 200px; white-space: pre-wrap">
                    {{ notification?.message ?? "--" }}
                  </p>
                  <p class="tx-12 text-muted">
                    {{ notification?.created_at | amTimeAgo }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img
            class="wd-30 ht-30 rounded-circle"
            src="assets/images/placeholder/user.jpeg"
            alt="profile"
          />
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div
            class="d-flex flex-column align-items-center border-bottom px-5 py-3"
          >
            <div class="mb-3">
              <img
                class="wd-80 ht-80 rounded-circle"
                src="assets/images/placeholder/user.jpeg"
                alt=""
              />
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">
                {{ user?.firstName }} {{ user?.lastName }}
              </p>
              <p class="tx-12 text-muted">{{ user?.email }}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <!-- <li class="dropdown-item py-2">
              <a (click)="(false)" class="d-flex text-body ms-0">
                <i class="feather icon-user me-2 icon-md"></i>
                <span>Profile</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a
                href="javascript: void(0)"
                (click)="(false)"
                class="d-flex text-body ms-0"
              >
                <i class="feather icon-edit me-2 icon-md"></i>
                <span>Edit Profile</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a
                href="javascript: void(0)"
                (click)="(false)"
                class="d-flex text-body ms-0"
              >
                <i class="feather icon-repeat me-2 icon-md"></i>
                <span>Switch User</span>
              </a>
            </li> -->
            <li class="dropdown-item py-2" (click)="changePassword()">
              <a href="javascript: void(0)" class="d-flex text-body ms-0">
                <i class="feather icon-lock me-2 icon-md"></i>
                <span>Change Password</span>
              </a>
            </li>
            <li class="dropdown-item py-2" (click)="onLogout()">
              <a href="javascript: void(0)" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>
