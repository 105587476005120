import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Quotation } from '../../models/quotation.model';
import { TableHeader } from '../../interfaces/table-header.interface';
import { Subject, debounceTime, switchMap } from 'rxjs';
import { QuotationService } from '../../../core/services/quotation.service';
import { CommonResponse } from '../../interfaces/common-response.interface';
import {
  DatatablePage,
  DatatableSort,
} from '../../interfaces/datatable-action.interface';

@Component({
  selector: 'app-quotation-activity',
  templateUrl: './quotation-activity.component.html',
  styleUrl: './quotation-activity.component.scss',
})
export class QuotationActivityComponent implements OnInit {
  @Input() quotation: Quotation;

  /**
   * The row entries to be passes to the datatable for displaying
   *
   * @type {Object[]}
   */
  rows: Object[] = [];

  /**
   * The headers of each rows to be showed in the datatable
   *
   * @type {TableHeader[]}
   */
  headers: TableHeader[] = [
    {
      name: 'Action',
      prop: 'actionName',
    },
    {
      name: 'Action Done By',
      prop: 'user.fullName',
    },
    {
      name: 'Date',
      prop: 'updateDate',
    },
  ];

  /**
   * Boolean condition for showing a loading indicator in the datatable
   *
   * @type {boolean}
   */
  loadingIndicator: boolean = true;

  /**
   * Boolean flag to check filter applied state of the datatable.
   * @type {boolean}
   */
  filterApplied: boolean = false;

  /**
   * Boolean condition to show select button in datatable.
   *
   * @type {boolean}
   */
  actionButton: boolean = true;

  /**
   * Module Permissions.
   */
  modulePermissions: string[] = [];

  // Properties for fetching api
  skip = 0;
  take = 10;
  limit = 10;
  sortOrder: string = 'desc';
  sortValue: string = 'created_at';
  totalCount = 0;
  search: string = null;
  filter: { dateFilter: string; fromDate?: string; toDate?: string } = {
    dateFilter: '',
    fromDate: '',
    toDate: '',
  };
  action: string = null;

  fetch$ = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private quotationService: QuotationService
  ) {}

  ngOnInit(): void {
    this.getActivities();

    this.fetch$
      .pipe(
        debounceTime(300),
        switchMap(() => {
          // Set loading indicator to true before making the API call
          this.loadingIndicator = true;

          // Prepare payload with parameters for the API call
          const payload = {
            sortOrder: this.sortOrder,
            sortValue: this.sortValue,
            skip: this.skip,
            take: this.take,
            search: this.search,
            filter: this.filter,
            quotationId: this.quotation?._id,
          };
          return this.quotationService.getActivities(payload);
        })
      )
      .subscribe({
        next: (
          response: CommonResponse<{
            activityLog: Object[];
            totalCount: number;
            filteredCount: number;
          }>
        ) => {
          if (response?.status == 'success') {
            this.rows = response?.data?.activityLog;
            this.totalCount = response?.data?.filteredCount;
            this.loadingIndicator = false;
          } else {
            this.rows = [];
            this.totalCount = 0;
            this.loadingIndicator = false;
          }
        },
        error: (error) => {
          this.rows = [];
          this.totalCount = 0;
          this.loadingIndicator = false;
        },
      });
  }

  getActivities() {
    // Set loading indicator to true before making the API call
    this.loadingIndicator = true;

    // Prepare payload with parameters for the API call
    const payload = {
      sortOrder: this.sortOrder,
      sortValue: this.sortValue,
      skip: this.skip,
      take: this.take,
      search: this.search,
      filter: this.filter,
      quotationId: this.quotation?._id,
    };

    this.quotationService.getActivities(payload).subscribe({
      next: (
        response: CommonResponse<{
          activityLog: Object[];
          totalCount: number;
          filteredCount: number;
        }>
      ) => {
        if (response?.status == 'success') {
          this.rows = response?.data?.activityLog;
          this.totalCount = response?.data?.filteredCount;
          this.loadingIndicator = false;
        } else {
          this.rows = [];
          this.totalCount = 0;
          this.loadingIndicator = false;
        }
      },
      error: (error) => {
        this.rows = [];
        this.totalCount = 0;
        this.loadingIndicator = false;
      },
    });
  }

  /**
   * Handles the datatable page event, updating the skip value and fetching the updated quotation list.
   *
   * @method
   *
   * @remarks
   * This method is typically used as an event handler for pagination events in a datatable.
   * It calculates the new value for 'skip' based on the offset and 'limit', then triggers the
   * retrieval of quotations with the updated parameters.
   *
   * @param event - The page event emitted by the datatable component.
   *
   * @remarks
   * **Parameters:**
   * - `event` - The page event object containing information about the current page.
   */
  dtOnPage(event: DatatablePage) {
    this.skip = event.offset * this.limit;
    this.getActivities();
  }

  /**
   * Handles the datatable limit change event, updating the limit, skip, take values, and fetching the updated quotation list.
   *
   * @remarks
   * This method is typically used as an event handler for limit change events in a datatable.
   * It updates the 'limit' property, resets the 'skip' to 0, adjusts the 'take' value,
   * and triggers the retrieval of quotations with the updated parameters.
   *
   * @param event - The new limit value emitted by the datatable component.
   *
   * @remarks
   * **Parameters:**
   * - `event` - The new limit value set by the user.
   */
  dtOnLimit(event: number) {
    this.limit = event;
    this.skip = 0;
    this.take = this.limit;
    this.getActivities();
  }

  /**
   * Handles the Search query change in the quotation list table.
   *
   * @remarks
   * This method is used as an event handler for query change events in search function  in table.
   * It updates the 'search' property,resets the 'skip' to 0, adjusts the take value,
   * and triggers the retrieval of quotations with the updated parameters.
   *
   * @param event - The new search value emitted by the datatable component.
   */
  dtOnSearch(event: string) {
    this.search = event;
    this.skip = 0;
    this.take = this.limit;
    this.getActivities();
  }

  /**
   * Handles the Sorting event from the quick quotation list table.
   *
   * @param {DatatableSort} event
   */
  dtOnSort(event: DatatableSort) {
    this.sortOrder = event ? event?.sorts[0]?.dir : '';
    this.sortValue = event ? event?.sorts[0]?.prop : '';
    this.fetch$.next();
  }
}
