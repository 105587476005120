import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { Messages } from '../../enums/messages.enum';
import { LoggerService } from '../../../core/logger.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-technical',
  templateUrl: './assign-technical.component.html',
  styleUrl: './assign-technical.component.scss',
})
export class AssignTechnicalComponent implements OnInit {
  @Input() quotationId: string = null;
  selectTechnicalForm: FormGroup;
  technicalTeam: { _id: string; name: string; email: string }[] = [];
  submitted = false;
  loading = false;
  errorMessage: string = null;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private loggerService: LoggerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTechnicalTeam();
    this.selectTechnicalForm = new FormGroup({
      technicalTeam: new FormControl(null, [Validators.required]),
    });
  }

  getTechnicalTeam() {
    this.userService.getTechnicalTeam().subscribe((response) => {
      if (response?.status === 'success') {
        this.technicalTeam = response?.data;
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.selectTechnicalForm.invalid) {
      return;
    }
    this.loading = true;

    const payload = this.selectTechnicalForm.value;
    payload.quotationId = this.quotationId;
    this.userService.assignToTechnicalMember(payload).subscribe({
      next: (response) => {
        if (response?.status === 'success') {
          this.loggerService.successMessage$.next(response?.message);
          this.router.navigate(['quotations']);
          this.activeModal.close();
        } else {
          this.errorMessage = response?.message ?? Messages.SomethingWentWrong;
          this.loading = false;
        }
      },
      error: (error) => {
        this.errorMessage = error;
        this.loading = false;
      },
    });
  }
}
