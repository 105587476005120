import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Messages } from '../../shared/enums/messages.enum';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler {
  /**
   * Function for error handling
   *
   * This function receives the errors and throw appropriate error messages
   *
   * @param errorResponse | is the response if an error occurs
   * @returns throws error message
   */
  errorHandler(errorResponse: HttpErrorResponse) {
    let errorMessage = Messages.SomethingWentWrong;
    if (!errorResponse.status) {
      return throwError(() => new Error(errorMessage));
    }
    switch (errorResponse.status) {
      case 422:
        errorMessage = Messages.ValidationFails;
        break;
      case 401:
        errorMessage = Messages.UnAuthorizedAccess;
        break;
      case 402:
        errorMessage = Messages.InsufficientPermission;
        break;
      case 404:
        errorMessage = Messages.NotFound;
        break;
      case 500:
        errorMessage = Messages.SomethingWentWrong;
        break;
    }
    return throwError(() => new Error(errorMessage));
  }
}
