import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { blankValidator } from '../../../../shared/validators/blank.validator';
import { UserService } from '../../../../core/services/user.service';
import { LoggerService } from '../../../../core/logger.service';
import { Messages } from '../../../../shared/enums/messages.enum';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit {
  /**
   * Boolean to check the Submitting state of the Form.
   */
  isSubmitting: boolean = false;

  /**
   * String to store the error message.
   */
  errorMessage: string = null;

  /**
   * FormGroup instance variable.
   */
  form: FormGroup;

  /**
   * Boolean flag to check the submitted state of the form.
   */
  submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      oldPassword: new FormControl(null, [
        Validators.required,
        blankValidator(),
      ]),
      newPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
        blankValidator(),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
        blankValidator(),
      ]),
    });
  }

  /**
   * Function to change the password of the current User.
   */
  changePassword() {
    this.submitted = true;
    this.isSubmitting = true;
    const payload = this.form.value;
    if (this.form.valid && payload.newPassword == payload.confirmPassword) {
      this.userService.changePassword(payload).subscribe({
        next: (response) => {
          if (response?.status === 'success') {
            this.loggerService.successMessage$.next(
              Messages.PasswordChangeSuccess
            );
            this.activeModal.close();
          } else {
            this.errorMessage =
              response?.message ?? Messages.SomethingWentWrong;
          }
        },
        error: (error) => {
          this.errorMessage = error;
        },
        complete: () => {
          this.isSubmitting = false;
        },
      });
    }
  }
}
