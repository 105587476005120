import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbAlertModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModalModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  NgbDropdownModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MomentModule } from 'ngx-moment';

import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FeatherIconDirective } from './directives/feather-icon.directive';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContentAnimateDirective } from './directives/content-animate.directive';
import { FooterComponent } from './components/footer/footer.component';
import { InnerHeaderComponent } from './components/inner-header/inner-header.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { DetailsPageComponent } from './components/details-page/details-page.component';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { ViewQuickQuoteComponent } from './components/view-quick-quote/view-quick-quote.component';
import { WarningAlertComponent } from './components/warning-alert/warning-alert.component';
import { AssignTechnicalComponent } from './components/assign-technical/assign-technical.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AssignAgentComponent } from './components/assign-agent/assign-agent.component';
import { QuotationActivityComponent } from './components/quotation-activity/quotation-activity.component';
import { SuspendedEmailComponent } from './components/suspended-email/suspended-email.component';
import { CustomDateAdapter } from './directives/custom-date-adaptor';
import { CustomDateParserFormatter } from './directives/custom-date-parser';
import { DigitOnlyDirective } from './directives/digit-only.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    BaseLayoutComponent,
    SidebarComponent,
    FeatherIconDirective,
    NavbarComponent,
    ContentAnimateDirective,
    FooterComponent,
    InnerHeaderComponent,
    DatatableComponent,
    DetailsPageComponent,
    TableFilterComponent,
    ViewQuickQuoteComponent,
    WarningAlertComponent,
    AssignTechnicalComponent,
    AssignAgentComponent,
    QuotationActivityComponent,
    SuspendedEmailComponent,
    DigitOnlyDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    RouterModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbModalModule,
    NgxDatatableModule,
    ClipboardModule,
    NgSelectModule,
    NgbTooltipModule,
    MomentModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    RouterModule,
    NgbModalModule,
    InnerHeaderComponent,
    FeatherIconDirective,
    DatatableComponent,
    ClipboardModule,
    DigitOnlyDirective,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SharedModule {}
