<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">Assign to Technical Team</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <form
      id="selectTechnicalForm"
      [formGroup]="selectTechnicalForm"
      (ngSubmit)="onSubmit()"
    >
      <label class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</label>
      <div class="mb-4">
        <label for="technicalTeam" class="form-label"
          >Technical Member Name <span class="text-danger">*</span></label
        >
        <ng-select
          formControlName="technicalTeam"
          id="technicalTeam"
          placeholder="Select technical member"
        >
          <ng-option
            *ngFor="let advisor of technicalTeam"
            [value]="advisor._id"
            >{{ advisor?.name | titlecase }}</ng-option
          >
        </ng-select>
        <div
          *ngIf="
            submitted &&
            selectTechnicalForm.get('technicalTeam').hasError('required')
          "
          class="mt-1"
        >
          <small class="text-danger"
            >Please select technical team member.</small
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-none">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
      [disabled]="loading"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="loading"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="loading"
      ></span>
      Assign
    </button>
  </div>
</div>
