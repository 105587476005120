import { Component, Input } from '@angular/core';
import { Quotation } from '../../models/quotation.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationService } from '../../../core/services/quotation.service';
import { LoggerService } from '../../../core/logger.service';

@Component({
  selector: 'app-suspended-email',
  templateUrl: './suspended-email.component.html',
  styleUrl: './suspended-email.component.scss',
})
export class SuspendedEmailComponent {
  @Input() quotations: Quotation[] = [];
  @Input() planId: string = null;

  sending = false;

  constructor(
    public activeModal: NgbActiveModal,
    private quotationService: QuotationService,
    private loggerService: LoggerService
  ) {}

  sendMail() {
    this.sending = true;
    const quotationIds = this.quotations.map((quotation) => {
      return quotation._id;
    });
    const payload = {
      quotationIds: quotationIds,
      planId: this.planId,
    };
    this.quotationService.sendSuspendedEmail(payload).subscribe({
      next: (response) => {
        if (response?.status === 'success') {
          this.loggerService.successMessage$.next(response?.message);
          this.sending = false;
          this.activeModal.close();
        } else {
          this.loggerService.errorMessage$.next(response?.message);
          this.sending = false;
        }
      },
      error: (error) => {
        this.loggerService.errorMessage$.next(error);
        this.sending = false;
      },
    });
  }
}
