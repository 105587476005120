import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/quick-quote.service';
import { ActivatedRoute, Params } from '@angular/router';
import { QuickQuote } from '../../models/quick-quote.model';

@Component({
  selector: 'app-view-quick-quote',
  templateUrl: './view-quick-quote.component.html',
  styleUrl: './view-quick-quote.component.scss',
})
export class ViewQuickQuoteComponent implements OnInit {
  // Boolean flag to check loading status of the Page.
  isLoading: boolean = true;

  // Id of the quick Quotation.
  quickQuoteId: string;

  quickQuote: QuickQuote;

  constructor(
    private location: Location,
    private active: ActivatedRoute,
    private quickQuoteService: QuickQuoteService
  ) {}

  ngOnInit(): void {
    this.active.params.subscribe((params: Params) => {
      this.quickQuoteId = params['quickQuoteId'];
    });
    if (this.quickQuoteId) {
      this.quickQuoteService
        .getQuickQuote(this.quickQuoteId)
        .subscribe((response) => {
          this.quickQuote = response.data;
          this.isLoading = false;
        });
    }
  }

  /**
   * Function to go back to the previous route.
   */
  goBack() {
    this.location.back();
  }
}
