import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { ErrorHandler } from '../helpers/error-handler';
import { Observable, catchError, map } from 'rxjs';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { InsurancePlan } from '../../shared/models/insurance-plan.model';
import { Quotation } from '../../shared/models/quotation.model';

@Injectable({
  providedIn: 'root',
})
export class InsurancePlansService {
  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler
  ) {}

  /**
   * Get Filtered plans of a quotation.
   * @param {string} quotationId
   * @returns {Observable<InsurancePlan[]>}
   */
  getFilteredInsurancePlans(quotationId: string): Observable<InsurancePlan[]> {
    return this.apiService
      .get<CommonResponse<{ matchingPlan: InsurancePlan[] }>>(
        `insurance-plans?quotationId=${quotationId}`
      )
      .pipe(
        map((response) => {
          if (
            response?.data.matchingPlan &&
            response?.data.matchingPlan.length > 0
          ) {
            return response.data.matchingPlan;
          } else {
            return [];
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Get Insurance plans of a particular Provider.
   * @param {string} providerId
   * @returns {Observable<InsurancePlan[]>}
   */
  getProvidersInsurancePlans(providerId: string): Observable<InsurancePlan[]> {
    return this.apiService
      .get<CommonResponse<InsurancePlan[]>>(`plans/${providerId}`)
      .pipe(
        map((response) => {
          if (response?.data && response.data.length > 0) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Get Insurance plan using its ID.
   * @param {string} planId
   * @returns {Observable<InsurancePlan>}
   */
  getInsurancePlanById(
    planId: string,
    quotationId: string = ''
  ): Observable<InsurancePlan> {
    return this.apiService
      .get<CommonResponse<[InsurancePlan]>>(`plan/${planId}/${quotationId}`)
      .pipe(
        map((response) => {
          return response.data[0];
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  getSuspendedPlans() {
    return this.apiService
      .get<CommonResponse<InsurancePlan[]>>(`plans/suspended`)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Function to Save Plans for a quotation.
   * @param {Object} body
   * @returns {Observable<Object>}
   */
  postSelectedPlans(body: Object): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>('save-plans', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Function to get saved plans of a quotation.
   * @param {string} quotationId
   * @returns {Observable<InsurancePlan[]>}
   */
  getReviewPlans(quotationId: string): Observable<InsurancePlan[]> {
    return this.apiService
      .get<CommonResponse<InsurancePlan[]>>(`review-plans/${quotationId}`)
      .pipe(
        map((response) => {
          if (response?.data && response.data.length > 0) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Function to get saved plans of a quotation using qrf.
   * @param {string} qrf
   * @returns {Observable<InsurancePlan[]>}
   */
  getQRFReviewPlans(qrf: string): Observable<{
    plansDetails: InsurancePlan[];
    quotationDetails: Quotation;
  }> {
    return this.apiService
      .get<
        CommonResponse<{
          plansDetails: InsurancePlan[];
          quotationDetails: Quotation;
        }>
      >(`review-plans/qrf/${qrf}`)
      .pipe(
        map((response) => {
          if (response?.data) {
            return response.data;
          } else {
            return { plansDetails: [], quotationDetails: null };
          }
        })
      );
  }

  /**
   * Function to post comments on Quotation.
   * @param {string} quotationId
   * @param {Object} body
   * @returns {Observable<Object>}
   */
  postComments(
    quotationId: string,
    body: Object
  ): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>(`comments/${quotationId}`, body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }
}
