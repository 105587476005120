<nav class="page-breadcrumb quotation_head">
  <div>
    <h2>{{ header }}</h2>
    <ol class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a routerLink=".">Dashboard</a></li>
      <li class="breadcrumb-item active">Create Quotation</li> -->
      <li
        class="breadcrumb-item"
        *ngFor="let breadcrumb of breadcrumbs"
        [ngClass]="{ active: breadcrumb.active }"
      >
        <a *ngIf="!breadcrumb?.active" routerLink="{{ breadcrumb?.link }}">{{
          breadcrumb.title
        }}</a>
        <span *ngIf="breadcrumb?.active">{{ breadcrumb.title }}</span>
      </li>
    </ol>
  </div>
</nav>
