import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-logout-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout-confirmation.component.html',
  styleUrl: './logout-confirmation.component.scss',
})
export class LogoutConfirmationComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService
  ) {}

  onLogout() {
    this.authService.logout();
    this.activeModal.close();
  }
}
