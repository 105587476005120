<button class="btn btn-outline-primary mb-4" (click)="goBack()">
  <i class="feather icon-arrow-left"></i>
  Back
</button>

<div class="col-md-12 stretch-card">
  <div class="quotation_head">
    <h2>Quick Quotation Details</h2>
  </div>
</div>

<!-- Spinner to load Client Form -->
<div *ngIf="isLoading" class="spinner-wrapper">
  <div class="spinner">Loading...</div>
</div>

<div class="col-md-12 stretch-card mt-4" *ngIf="quickQuote">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="mb-4">
            <h5 class="mb-2">Quick Quotation Name</h5>
            <p class="text-muted">{{ quickQuote.quoteName }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-4">
            <h5 class="mb-2">Quick Quotation Description</h5>
            <p class="text-muted">{{ quickQuote.description }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-4">
            <h5 class="mb-2">Created At</h5>
            <p class="text-muted">
              {{
                quickQuote.created_at_formatted | date : "dd, MMM yyyy hh:mm a"
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="quickQuote && quickQuote?.selectedPlans?.length > 0"
      >
        <div class="col-md-4" *ngIf="quickQuote?.selectedPlans?.length > 0">
          <div class="mb-4">
            <h5 class="mb-2">Selected plans</h5>
            <p class="text-muted" *ngFor="let plan of quickQuote.selectedPlans">
              {{ plan.planName }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="quickQuote?.filter">
        <div class="mb-4">
          <h5 class="mb-2">Applied Filters</h5>
          <p *ngIf="quickQuote?.filter?.amount">
            <strong>Annual Limit (AED / USD) : </strong>
            <span class="text-muted">{{
              quickQuote?.filter?.amount | number
            }}</span>
          </p>
          <p *ngIf="quickQuote?.filter?.area?.length > 0">
            <strong>Area : </strong>
            <span
              class="text-muted"
              *ngFor="let area of quickQuote?.filter?.area; let index = index"
            >
              <span *ngIf="index != 0">, </span>
              {{ area.name }}
            </span>
          </p>
          <p *ngIf="quickQuote?.filter?.copayForConsultation?.length > 0">
            <strong>Copay for Consultation : </strong>
            <span
              class="text-muted"
              *ngFor="
                let copayConsult of quickQuote?.filter?.copayForConsultation;
                let index = index
              "
              ><span *ngIf="index != 0">, </span> {{ copayConsult.name }}</span
            >
          </p>
          <p *ngIf="quickQuote?.filter?.copayForTest?.length > 0">
            <strong> Copay for Test and Medicine : </strong>
            <span
              class="text-muted"
              *ngFor="
                let copay of quickQuote?.filter?.copayForTest;
                let index = index
              "
            >
              <span *ngIf="index != 0">, </span>
              {{ copay.name }}
            </span>
          </p>
          <p *ngIf="quickQuote?.filter?.coverage">
            <strong>Coverage :</strong>
            <span class="text-muted">
              {{ quickQuote.filter.coverage }}
            </span>
          </p>
          <p *ngIf="quickQuote?.filter?.paymentFrequency">
            <strong> Payment Frequency : </strong>
            <span
              *ngFor="
                let payment of quickQuote?.filter?.paymentFrequency;
                let index = index
              "
              class="text-muted"
            >
              <span *ngIf="index != 0">, </span>
              {{ payment.name }}</span
            >
          </p>
          <p *ngIf="quickQuote?.filter?.dental">
            <strong> Dental : </strong>
            <span>Covered</span>
          </p>
          <p *ngIf="quickQuote?.filter?.optical">
            <strong> Optical : </strong>
            <span>Covered</span>
          </p>
          <p *ngIf="quickQuote?.providers?.length > 0">
            <strong>Insurance Providers : </strong>
            <span
              *ngFor="let provider of quickQuote?.providers; let index = index"
              class="text-muted"
            >
              <span *ngIf="index != 0">, </span>
              {{ provider.name }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
