<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">Logout</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <p>Are you sure, You want to Logout from the current session ?</p>
  </div>
  <div class="modal-footer border-none">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
    >
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="onLogout()">
      Logout
    </button>
  </div>
</div>
