<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">Change Password</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <ngb-alert
      (closed)="errorMessage = null"
      [type]="'danger'"
      *ngIf="errorMessage"
    >
      <i class="feather icon-moon"></i>
      {{ errorMessage }}
    </ngb-alert>
    <form [formGroup]="form">
      <div class="mb-4">
        <label for="password"
          >Current Password <span class="text-danger">*</span></label
        >
        <input
          type="password"
          id="password"
          class="form-control"
          placeholder="Enter Current Password"
          formControlName="oldPassword"
        />
        <div
          *ngIf="
            submitted &&
            (form.get('oldPassword').hasError('required') ||
              form.get('oldPassword').hasError('blank'))
          "
          class="mt-1"
        >
          <small class="text-danger">Please enter Current Password.</small>
        </div>
      </div>
      <div class="mb-4">
        <label for="new-password"
          >New Password <span class="text-danger">*</span></label
        >
        <input
          type="password"
          id="new-password"
          class="form-control"
          placeholder="Enter New Password"
          formControlName="newPassword"
        />
        <div
          *ngIf="
            submitted &&
            (form.get('newPassword').hasError('required') ||
              form.get('newPassword').hasError('blank'))
          "
          class="mt-1"
        >
          <small class="text-danger">Please enter New Password.</small>
        </div>
      </div>
      <div class="mb-4">
        <label for="confirm-password"
          >Confirm New Password <span class="text-danger">*</span></label
        >
        <input
          type="password"
          id="confirm-password"
          class="form-control"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
        />
        <div
          *ngIf="
            form.get('confirmPassword').value !== form.get('newPassword').value
          "
          class="mt-1"
        >
          <small class="text-danger">Passwords doesn't match.</small>
        </div>
        <div
          *ngIf="
            submitted &&
            (form.get('confirmPassword').hasError('required') ||
              form.get('confirmPassword').hasError('blank'))
          "
          class="mt-1"
        >
          <small class="text-danger">Please Confirm Password.</small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-none">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
    >
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="changePassword()">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="isSubmitting"
      ></span>
      Confirm
    </button>
  </div>
</div>
