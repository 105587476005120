<nav class="sidebar">
  <div class="sidebar-header">
    <!--- Logo -->
    <a routerLink="/" class="sidebar-brand nobleui-logo">
      <img
        src="assets/images/main/iib_logo.png"
        style="width: 80px; height: auto"
        alt=""
      />
    </a>

    <!--- Toggler -->
    <div
      class="sidebar-toggler not-active"
      #sidebarToggler
      (click)="toggleSidebar($event)"
    >
      <span></span>
      <span style="width: 50%"></span>
      <span></span>
    </div>
  </div>

  <div
    class="sidebar-body"
    (mouseenter)="openSidebarFolded()"
    (mouseleave)="closeSidebarFolded()"
  >
    <!--- Sidemenu start -->
    <ul class="sidebar-nav sidebar_button" id="sidebar-create-btn" #sidebarMenu>
      <li *ngIf="showCreateQuotation">
        <button
          class="btn primary-btn btn-lg"
          routerLink="/dashboard/create-quotation/create"
        >
          <i class="ri-add-line"></i>
          <span>Create Quotation</span>
        </button>
      </li>
    </ul>

    <ul
      class="sidebar-nav metismenu top_sidebar"
      id="sidebar-menu-items"
      #sidebarMenu
    >
      <ng-container *ngFor="let item of menuItems">
        <li
          class="nav-item nav-category"
          *ngIf="item.isTitle"
          style="color: #fff; font-weight: 400"
        >
          {{ item.label }}
        </li>
        <li class="nav-item" *ngIf="!item.isTitle && !item.restricted">
          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
            <i
              class="link-icon"
              [attr.data-feather]="item.icon"
              appFeatherIcon
              *ngIf="item.icon"
            ></i>
            <span class="link-title"> {{ item.label }}</span>
            <span
              class="link-arrow"
              data-feather="chevron-down"
              appFeatherIcon
            ></span>
          </a>

          <a
            class="nav-link nav-link-ref"
            [routerLink]="item.link"
            *ngIf="!hasItems(item)"
            [attr.data-parent]="item.parentId"
          >
            <i
              class="link-icon"
              [attr.data-feather]="item.icon"
              appFeatherIcon
              *ngIf="item.icon"
            ></i>
            <span class="link-title"> {{ item.label }}</span>
            <span
              class="badge bg-{{ item.badge.variant }}"
              *ngIf="item.badge"
              >{{ item.badge.text }}</span
            >
          </a>

          <ul
            class="sidebar-nav sub-menu nav-second-level"
            *ngIf="hasItems(item)"
            aria-expanded="false"
          >
            <li
              class="nav-item"
              *ngFor="let subitem of item.subItems"
              [ngClass]="{ 'side-nav-item': hasItems(subitem) }"
            >
              <a
                class="nav-link side-nav-link-a-ref"
                *ngIf="hasItems(subitem)"
                href="javascript:void(0);"
              >
                <i
                  class="link-icon"
                  [attr.data-feather]="subitem.icon"
                  appFeatherIcon
                  *ngIf="subitem.icon"
                ></i>
                <span class="link-title"> {{ subitem.label }}</span>
                <span
                  class="link-arrow"
                  data-feather="chevron-down"
                  appFeatherIcon
                ></span>
              </a>

              <a
                class="nav-link nav-link-ref"
                [routerLink]="subitem.link"
                *ngIf="!hasItems(subitem)"
                [attr.data-parent]="subitem.parentId"
              >
                <i
                  class="link-icon"
                  [attr.data-feather]="subitem.icon"
                  appFeatherIcon
                  *ngIf="subitem.icon"
                ></i>
                {{ subitem.label }}
              </a>

              <ul
                class="sidebar-nav sub-menu nav-third-level"
                *ngIf="hasItems(subitem)"
                aria-expanded="false"
              >
                <li
                  class="nav-item"
                  *ngFor="let subSubitem of subitem.subItems"
                >
                  <a
                    class="nav-link nav-link-ref"
                    [routerLink]="subSubitem.link"
                    [routerLinkActive]="['active']"
                    [attr.data-parent]="subSubitem.parentId"
                  >
                    <i
                      class="link-icon"
                      [attr.data-feather]="subitem.icon"
                      appFeatherIcon
                      *ngIf="subitem.icon"
                    ></i>
                    {{ subSubitem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <!-- <li class="nav-item nav-category">Docs</li>
        <li class="nav-item">
          <a href="https://www.nobleui.com/angular/documentation/docs.html" target="_blank" class="nav-link">
            <i class="link-icon" data-feather="hash" appFeatherIcon></i>
            <span class="link-title">Documentation</span>
          </a>
        </li> -->
    </ul>

    <ul
      class="sidebar-nav metismenu bottom_sidebar"
      id="sidebar-menu-items2"
      #sidebarMenu
    >
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);">
          <i class="link-icon ri-folder-shield-2-line"></i>
          <span class="link-title">Privacy Policy</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);">
          <i class="link-icon ri-checkbox-multiple-line"></i>
          <span class="link-title">Terms & Conditions</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
