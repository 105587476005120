import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrl: './inner-header.component.scss',
})
export class InnerHeaderComponent {
  @Input() header: string;
  @Input() breadcrumbs: Breadcrumb[] = [];
}
