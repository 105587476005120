import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function blankValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value as string;

    if (!value || value.trim() === '') {
      return { blank: true };
    }

    return null;
  };
}
