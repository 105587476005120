export enum Messages {
  SomethingWentWrong = 'Something went wrong. Please try again later !',
  ValidationFails = 'Validation Fails! Please fill fields with proper value.',
  UnAuthorizedAccess = 'Unauthorized access.',
  InsufficientPermission = 'You do not have any permission to do this action.',
  ClientFormSuccess = 'Client form was saved successfully.',
  FilterFormSuccess = 'Filter form was saved successfully.',
  ReviewFormSuccess = 'Review was saved successfully.',
  ReviewFormSuccessTechnical = 'Review was saved successfully. You can now assign to agent for completion of Quotation.',
  SelectFormSuccess = 'Insurance plans successfully selected.',
  AddNewPlanSuccess = 'New plan successfully added.',
  QuotationCreated = 'Quotation successfully created.',
  QuotationEdited = 'Quotation updated successfully.',
  StatusChangeSuccess = 'Successfully changed Quotation status.',
  QuickQuoteCreated = 'Quick Quote successfully created.',
  QuickQuoteEdited = 'Quick Quote successfully updated.',
  QuickQuoteSuccess = 'Quick Quote successfully selected.',
  DeletionSuccess = 'Successfully deleted',
  PlanAlreadyExists = 'Plan already exists.',
  PasswordChangeSuccess = 'Successfully changed Password.',
  NotFound = 'Requested resource not found.',
}
