import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * The base URL for API requests.
   * @type {string}
   */
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * Makes an HTTP POST request.
   *
   * @template T - The type of the expected response.
   * @param {string} url - The endpoint URL.
   * @param {any} body - The request payload.
   * @param {HttpHeaders} [headers] - Optional HTTP headers.
   * @returns {Observable<T>} An observable of the HTTP response.
   */

  post<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    const options = { headers };
    return this.http.post<T>(`${this.apiUrl}${url}`, body, options);
  }

  /**
   * Makes an HTTP GET request.
   *
   * @template T - the type of expected response.
   * @param {string} url - the endpoint URL.
   * @param {HttpHeaders} headers - Optional HTTP headers.
   * @returns {Observable<T>} An observable of the HTTP response.
   */

  get<T>(url: string, headers?: HttpHeaders): Observable<T> {
    const options = { headers };
    return this.http.get<T>(`${this.apiUrl}${url}`, options);
  }

  getExport<T>(url: string, headers?: HttpHeaders): Observable<T> {
    const options = { headers, responseType: 'blob' as 'json' };
    return this.http.get<T>(`${this.apiUrl}${url}`, options);
  }

  /**
   * Makes an HTTP PUT request.
   *
   * @template T - The type of the expected response.
   * @param {string} url - the endpoint URL.
   * @param {any} body - The request payload.
   * @param {HttpHeaders} headers  Optional HTTP headers.
   * @returns {Observable<T>} An observable of the HTTP response.
   */

  put<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    const options = { headers };
    return this.http.put<T>(`${this.apiUrl}${url}`, body, options);
  }

  /**
   * Makes an HTTP PATCH request.
   *
   * @template T - The type of the expected response.
   * @param {string} url - the endpoint URL.
   * @param {any} body - The request payload.
   * @param {HttpHeaders} headers  Optional HTTP headers.
   * @returns {Observable<T>} An observable of the HTTP response.
   */

  patch<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    const options = { headers };
    return this.http.patch<T>(`${this.apiUrl}${url}`, body, options);
  }
}
