import { Injectable } from '@angular/core';
import { NgForage, NgForageCache } from 'ngforage';

@Injectable({
  providedIn: 'root',
})
export class LocalForageService {
  constructor(
    private readonly ngf: NgForage,
    private readonly ngfc: NgForageCache
  ) {}

  /**
   * @method getItem
   * @description In this function we returns the value using the key given
   * @param key
   * @returns
   */
  public getItem<T = any>(key: string): Promise<T> {
    return this.ngf.getItem<T>(key);
  }

  /**+
   * @method getCachedItem
   * @description In this function we returns the value using the key given from the cache
   */
  public async getCachedItem<T = any>(key: string): Promise<T | null> {
    const r = await this.ngfc.getCached<T>(key);
    if (!r.hasData || r.expired) {
      return null;
    }
    return r.data;
  }

  /**
   * @method setItem
   * @description Here we save the key and value in local forage
   * @param key
   * @param value
   */
  public setItem(key: string, value: any) {
    this.ngfc.setItem(key, value);
  }

  /**
   * @method removeItem
   * @description We remove the key and value using the key given
   * @param key
   */
  public removeItem(key: string) {
    this.ngfc.removeCached(key);
  }

  /**
   * @method clear
   * @description Here we clear all the items from local forage
   * @returns
   */
  public clear() {
    return this.ngfc.clear();
  }
}
