import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { BehaviorSubject, Observable, catchError, map, tap } from 'rxjs';
import { ErrorHandler } from '../helpers/error-handler';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { QuickQuote } from '../../shared/models/quick-quote.model';
import { InsurancePlan } from '../../shared/models/insurance-plan.model';
import { Quotation } from '../../shared/models/quotation.model';

@Injectable({
  providedIn: 'root',
})
export class QuickQuoteService {
  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler
  ) {}

  /**
   * A BehaviorSubject for observing changes to the quick quotation.
   * @type {BehaviorSubject<QuickQuote>}
   */
  quickQuote$: BehaviorSubject<QuickQuote> = new BehaviorSubject<QuickQuote>(
    null
  );

  /**
   * Posts quick quotation filters.
   * @method
   * @param {Object} body
   * @returns {Observable<CommonResponse<{ quickQuoteDetails: QuickQuote }>>}
   */
  postQuickQuotationFilter(
    body: Object
  ): Observable<CommonResponse<{ quickQuoteDetails: QuickQuote }>> {
    return this.apiService
      .post<CommonResponse<{ quickQuoteDetails: QuickQuote }>>(
        'quick-quotes',
        body
      )
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Gets plans based on the filters.
   * @method
   * @param {string} quickQuotationId
   * @returns {Observable<InsurancePlan[]>}
   */
  getQuickQuotePlans(quickQuotationId: string): Observable<InsurancePlan[]> {
    return this.apiService
      .get<CommonResponse<{ matchingPlan: InsurancePlan[] }>>(
        `quick-quotes/insurance-plans/?quickQuotationId=${quickQuotationId}`
      )
      .pipe(
        map((response) => {
          if (response?.data && response.data.matchingPlan.length > 0) {
            return response.data.matchingPlan;
          } else {
            return [];
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Posts selected plans for the quick quote.
   * @method
   * @param {Object} body
   * @returns {Observable<CommonResponse<Object>>}
   */
  postQuickQuotePlans(body: Object): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>('quick-quotes/save-plans', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Get a single Quick Quotation using ID.
   * @method
   * @param {string} quickQuoteId
   */
  getQuickQuote(quickQuoteId: string) {
    return this.apiService
      .get<CommonResponse<QuickQuote>>(`quick-quotes/${quickQuoteId}`)
      .pipe(
        tap((response) => {
          if (response?.data) {
            this.quickQuote$.next(response.data);
          }
        })
      );
  }

  /**
   * Lists all quick quotes.
   * @param {Object} body
   * @returns {Observable<CommonResponse<{quickQuotes: Object[];totalCount: number;filteredCount: number;}>>}
   */
  getAllQuickQuotes(body: Object): Observable<
    CommonResponse<{
      quickQuotes: Object[];
      totalCount: number;
      filteredCount: number;
    }>
  > {
    return this.apiService
      .post<
        CommonResponse<{
          quickQuotes: Object[];
          totalCount: number;
          filteredCount: number;
        }>
      >('quick-quotes/list', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Apply a quick quote to a quotation.
   * @param body
   * @returns
   */
  applyQuickQuote(
    body: Object
  ): Observable<CommonResponse<{ quotation: Quotation }>> {
    return this.apiService
      .post<CommonResponse<{ quotation: Quotation }>>(
        'quick-quotes/apply',
        body
      )
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Delete a quick quote.
   * @param {string} id
   * @returns {Observable<CommonResponse<Object>>}
   */
  deleteQuickQuote(id: string): Observable<CommonResponse<Object>> {
    return this.apiService
      .patch<CommonResponse<Object>>(`quick-quotes/delete/${id}`, '')
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Save as a Quick quote.
   * @param { Object } body
   * @returns {Observable<Object>}
   */
  saveASQuickQuote(body: Object): Observable<CommonResponse<Object>> {
    return this.apiService
      .post<CommonResponse<Object>>('quick-quotes/from-quotation', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }
}
