<div class="dataTable-top">
  <div class="dataTable-dropdown">
    <label
      ><select
        class="dataTable-selector"
        fdprocessedid="f3hyp"
        [(ngModel)]="limit"
        (change)="onLimitChange()"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
      </select>
      entries per page</label
    >
  </div>
  <div class="d-flex gap-2">
    <div class="dataTable-search">
      <input
        class="dataTable-input"
        placeholder="Search..."
        type="text"
        fdprocessedid="yf1odi"
        [(ngModel)]="search"
        (keyup)="onSearchChange()"
      />
    </div>
    <button
      *ngIf="enableSelection"
      ngbTooltip="Send Plan Suspension Notification 
      to selected Customers"
      class="btn btn-outline-dark table_bottom_btn"
      (click)="dtOnSend.emit(selected)"
    >
      <i class="ri-mail-send-line"></i>
    </button>
    <button
      *ngIf="filter"
      class="btn btn-outline-dark table_bottom_btn"
      (click)="dtOnFilter.emit('filter')"
    >
      <span *ngIf="filterApplied" class="filter_dot"></span>
      <i class="ri-filter-3-line"></i>
      Filter
    </button>
    <button
      *ngIf="export"
      class="btn btn-outline-dark table_bottom_btn"
      (click)="dtOnExport.emit('export')"
    >
      <i class="ri-download-2-line"></i>
      Export
    </button>
  </div>
</div>
<div class="table-responsive mt-3">
  <ngx-datatable
    #table
    class="bootstrap"
    [columns]="headers"
    [loadingIndicator]="loadingIndicator"
    [columnMode]="ColumnMode.force"
    [footerHeight]="50"
    rowHeight="auto"
    [count]="totalItems"
    [limit]="limit"
    [externalPaging]="externalPaging"
    [externalSorting]="externalSorting"
    [rows]="rows"
    (page)="dtOnPage.emit($event)"
    (sort)="dtOnSort.emit($event)"
    (activate)="onActivate($event)"
    [sorts]="sorts"
    [scrollbarH]="true"
    [selected]="selected"
    [selectAllRowsOnPage]="false"
    [selectionType]="SelectionType.checkbox"
    (select)="onSelect($event)"
  >
    <ngx-datatable-column
      *ngIf="enableSelection"
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [checkboxable]="true"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="SL NO"
      [sortable]="false"
      *ngIf="slNo"
      [minWidth]="90"
      [width]="90"
      [maxWidth]="90"
    >
      <ng-template
        let-row="row"
        let-value="value"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        {{ "#" + (rowIndex + 1 + table.offset * limit) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let col of headers"
      [name]="col.name"
      [prop]="col.prop"
      [sortable]="col?.sortable"
      [width]="col.prop === 'customer.userName' ? 300 : 170"
    >
      <ng-template
        let-row="row"
        let-rowIndex="rowIndex"
        ngx-datatable-cell-template
        let-value="value"
      >
        <div [ngSwitch]="col.prop">
          <span *ngSwitchDefault>{{ !!value ? value : "--" }}</span>
          <div
            *ngSwitchCase="'quotationId'"
            role="button"
            (click)="dtAction.emit({ action: col?.action, data: row })"
          >
            {{ row?.quotationId }}
          </div>
          <div *ngSwitchCase="'customer.userName'">
            <div class="d-flex gap-2">
              <div class="color_{{ (rowIndex + 1) % 6 }} user_icon">
                <span>{{
                  getShortName(row?.customer?.emirateName) | uppercase
                }}</span>
              </div>
              <div class="user_media d-flex align-items-center">
                {{ row?.customer?.userName }}
              </div>
            </div>
          </div>
          <span *ngSwitchCase="'quotationAdditionalMembers'">{{
            row?.customer?.additionalUserCount ?? "--"
          }}</span>
          <span *ngSwitchCase="'quotationTimestamp'">{{
            row?.quotationTimestamp
              ? (row?.quotationTimestamp | date : "dd, MMM yyyy hh:mm a")
              : "--"
          }}</span>
          <span *ngSwitchCase="'updated_at'">{{
            row?.updated_at
              ? (row?.updated_at | date : "dd, MMM yyyy hh:mm a")
              : "--"
          }}</span>
          <span *ngSwitchCase="'selectedPlans.premiumTotal'">
            <span
              >{{
                row?.assignedPlanDetails?.planType === "International"
                  ? "USD"
                  : "AED"
              }}
              {{ value ? value : "--" }}</span
            >
          </span>
          <span *ngSwitchCase="'insuranceDate'">{{
            row?.insuranceDate
              ? (row?.insuranceDate | date : "dd, MMM yyyy")
              : "--"
          }}</span>
          <span *ngSwitchCase="'quotationAdviser'">{{
            row?.advicerdetails?.advicerName | titlecase
          }}</span>
          <span *ngSwitchCase="'description'">{{
            (value | slice : 0 : 50) + (value?.length > 50 ? "..." : "")
          }}</span>
          <span *ngSwitchCase="'created_at'">{{
            value | date : "medium"
          }}</span>
          <span *ngSwitchCase="'selectedPlansCount'">{{ value ?? "--" }}</span>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="actions.length > 0" [sortable]="false">
      <ng-template
        let-row="row"
        let-rowIndex="rowIndex"
        ngx-datatable-cell-template
        let-value="value"
      >
        <!--  -->
        <div
          ngbDropdown
          placement="bottom-end"
          container="body"
          class="btn-group"
          *ngIf="actions.length > 0"
        >
          <button class="btn btn-icon" id="dropupMenu" ngbDropdownToggle>
            <i class="feather icon-more-vertical"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropupMenu">
            <ng-container *ngFor="let action of actions">
              <button
                *ngIf="
                  action?.action !== 'deleteDraft' ||
                  row?.createdBy === currentUser?._id
                "
                class="dropdown-item"
                (click)="dtAction.emit({ action: action.action, data: row })"
              >
                <i class="{{ action.icon }}"></i> {{ action.title }}
              </button>
            </ng-container>
          </div>
        </div>
        <!--  -->
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name=""
      [sortable]="false"
      *ngIf="actionButton"
      [minWidth]="50"
    >
      <ng-template
        let-row="row"
        let-value="value"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <button class="btn btn-primary" (click)="dtButtonAction.emit(row?._id)">
          Select Quick Quote
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template> </ngx-datatable-footer
  ></ngx-datatable>
</div>
