import { Injectable } from '@angular/core';
import { Notification } from '../../shared/models/notification.model';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { ApiService } from '../http/api.service';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { ErrorHandler } from '../helpers/error-handler';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: Notification[] = [];

  notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<
    Notification[]
  >([]);

  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler
  ) {}

  getNotifications(userId: string) {
    return this.apiService
      .get<CommonResponse<Notification[]>>(`notifications/${userId}`)
      .pipe(
        tap((response: CommonResponse<Notification[]>) => {
          this.notifications = response?.data ?? [];
          this.notifications$.next(this.notifications);
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  markAsViewed(notificationId: string) {
    return this.apiService
      .post<CommonResponse<Notification>>('notifications/viewed', {
        notificationId,
      })
      .pipe(
        tap((response: CommonResponse<Notification>) => {
          this.notifications = this.notifications.filter(function (
            notification: Notification
          ) {
            return notification._id !== notificationId;
          });
          this.notifications$.next(this.notifications);
        })
      );
  }
}
