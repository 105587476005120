<!-- Changes needed -->
<button class="btn btn-outline-primary mb-4" (click)="goBack()">
  <i class="feather icon-arrow-left"></i>
  Back
</button>

<!-- Spinner to load Client Form -->
<div *ngIf="isLoading" class="spinner-wrapper">
  <div class="spinner">Loading...</div>
</div>
<!-- Quotation Details -->
<div *ngIf="!isLoading">
  <div class="col-md-12 stretch-card">
    <div class="quotation_head gap-3">
      <h2>Quotation Details</h2>
      <h4>
        <span [ngClass]="statusClass(quotation.quotationStatus.name)">
          {{ quotation.quotationStatus.name }}
        </span>
      </h4>
    </div>
  </div>
  <div class="col-md-12 stretch-card mt-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card_head">Quotation Details</h6>
        <div class="create_quotation_table">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Quotation Id</h5>
                <p class="text-muted">{{ quotation.quotationId }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Timestamp of Quotation</h5>
                <p class="text-muted">
                  {{
                    quotation.quotationTimestamp | date : "dd, MMM yyyy hh:mm a"
                  }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Revision Number</h5>
                <p class="text-muted">{{ quotation.revisionNumber }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Advisor Name</h5>
                <p class="text-muted">
                  {{ quotation?.advicerdetails?.advicerName ?? "--" }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Advisor Email</h5>
                <p class="text-muted">
                  {{ quotation?.advicerdetails?.advicerEmail ?? "--" }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Quotation Generated Date</h5>
                <p class="text-muted">
                  {{
                    quotation.startDate ?? quotation.customer.startDate
                      | date : "dd-MM-yyyy" || "--"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Status details -->
  <!-- if status is 'Lost' -->
  <div
    class="col-md-12 stretch-card mt-4"
    *ngIf="quotation.quotationStatus.name === 'Lost' && quotation.lostReason"
  >
    <div class="card">
      <div class="card-body">
        <h6 class="card_head">Status Details</h6>
        <div class="create_quotation_table">
          <div class="row">
            <div class="col-md-4 mb-4">
              <h5 class="mb-2">Reason to Lose</h5>
              <p class="text-muted">{{ quotation?.lostReason }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- if status is 'Closed Won' -->
  <div
    class="col-md-12 stretch-card mt-4"
    *ngIf="quotation.quotationStatus.name === 'Closed won'"
  >
    <div class="card">
      <div class="card-body">
        <h6 class="card_head">Status Details</h6>
        <div class="create_quotation_table">
          <div class="row">
            <div class="col-md-4 mb-4">
              <h5 class="mb-2">Insurance Date</h5>
              <p class="text-muted">
                {{ quotation?.insuranceDate | date : "dd-MM-yyyy" }}
              </p>
            </div>
            <div class="col-md-4 mb-4">
              <h5 class="mb-2">Expiry Date</h5>
              <p class="text-muted">
                {{ quotation?.expiryDate | date : "dd-MM-yyyy" }}
              </p>
            </div>
            <div class="col-md-4 mb-4">
              <h5 class="mb-2">Policy Document</h5>
              <a
                class="btn btn-primary"
                [href]="quotation.policyDocument.url"
                target="_blank"
                >View Document
              </a>
            </div>
            <div class="col-md-4 mb-4">
              <h5 class="mb-2">Assigned Plan</h5>
              <p class="text-muted">
                {{ quotation?.assignedPlanDetails?.planName ?? "--" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Customer Details -->

  <div class="col-md-12 stretch-card mt-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card_head">Customer Details</h6>
        <!-- row 1 start -->
        <div class="create_quotation_table">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="mb-2">Name as per Emirates ID</h5>
                  <p class="text-muted">
                    {{ quotation.customer?.emirateName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Email Address</h5>
                <p class="text-muted">{{ quotation.customer.email }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Phone Number</h5>
                <p class="text-muted">{{ quotation.customer.phoneNo }}</p>
              </div>
            </div>
          </div>
          <!-- row 1 end -->
          <!-- row 2 start -->
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Residency</h5>
                <p class="text-muted">
                  {{ quotation.customer.residency.name }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Nationality</h5>
                <p class="text-muted">
                  {{ quotation.customer.nationality.name }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Gender</h5>
                <p class="text-muted">{{ quotation.customer.gender }}</p>
              </div>
            </div>
          </div>
          <!-- row 2 end -->
          <!-- row 3 start -->
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Marital Status</h5>
                <p class="text-muted">
                  {{ quotation.customer.maritalStatus }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Date of Birth</h5>
                <p class="text-muted">
                  {{ quotation.customer.dob | date : "dd-MM-yyyy" }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Relation</h5>
                <p class="text-muted">
                  {{ quotation.customer.relation.relationName }}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <h5 class="mb-2">Existing Insurer</h5>
                <p class="text-muted">
                  {{
                    quotation?.customer?.previousInsurerDetails?.name ??
                      (quotation?.customer?.previousInsurerText ?? "--")
                  }}
                  <span *ngIf="quotation?.customer?.otherPreviousInsurer"
                    >({{ quotation?.customer?.otherPreviousInsurer }})</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- row 3 end -->
      </div>
    </div>
  </div>

  <!-- Additional Users -->
  <div *ngFor="let additionalUser of quotation.customer?.additionalUser">
    <div class="col-md-12 stretch-card mt-4">
      <div class="card">
        <div class="card-body">
          <h6 class="card_head">Additional Members</h6>
          <div class="create_quotation_table">
            <!-- row 1 start -->
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="mb-4 col-md-12">
                    <h5 class="mb-2">Name as per Emirates ID</h5>
                    <p class="text-muted">{{ additionalUser.emirateName }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Nationality</h5>
                  <p class="text-muted">
                    {{ additionalUser.nationality.nationalityName }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Date of Birth</h5>
                  <p class="text-muted">
                    {{ additionalUser.dob | date : "dd-MM-yyyy" }}
                  </p>
                </div>
              </div>
            </div>
            <!-- row 1 end -->
            <!-- row 2 start -->
            <div class="row">
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Gender</h5>
                  <p class="text-muted">{{ additionalUser.gender }}</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Relation</h5>
                  <p class="text-muted">
                    {{ additionalUser.relation.relationName }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Marital Status</h5>
                  <p class="text-muted">
                    {{ additionalUser.maritalStatus }}
                  </p>
                </div>
              </div>
            </div>
            <!-- row 2 end -->
            <div class="row">
              <div class="col-md-4">
                <div class="mb-4">
                  <h5 class="mb-2">Existing Insurer</h5>
                  <p class="text-muted">
                    {{
                      additionalUser?.previousInsurerDetails?.name ??
                        (additionalUser?.previousInsurerText ?? "--")
                    }}
                    <span *ngIf="additionalUser?.otherPreviousInsurer"
                      >({{ additionalUser?.otherPreviousInsurer }})</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Premium Details -->

  <div class="col-md-12 stretch-card mt-4" *ngIf="selectedPlans.length > 0">
    <div class="card">
      <div class="card-body">
        <h6 class="card_head">Premium Details</h6>
        <div>
          <div class="table-responsive pt-3">
            <table class="table table-bordered">
              <tbody class="review_table review_sub_table">
                <tr>
                  <th class="plain_row">
                    <table class="table table-bordered">
                      <tbody class="head_table">
                        <tr>
                          <td scope="col">Name</td>
                          <td scope="col">Age</td>
                          <td scope="col">Gender</td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                  <td
                    scope="col"
                    *ngFor="let plan of selectedPlans; let i = index"
                    class="plan_name_head"
                  >
                    {{ plan.planName }}
                  </td>
                </tr>
                <tr *ngFor="let customer of Customers; let index = index">
                  <th>
                    <table class="table">
                      <tbody class="head_table">
                        <tr>
                          <td>
                            {{ customer.name }}
                          </td>
                          <td>{{ customer.age }}</td>
                          <td>{{ customer.fromArea }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                  <td *ngFor="let plan of selectedPlans; let i = index">
                    {{
                      selectedPlanDetails[i]?.planType === "International"
                        ? "USD"
                        : "AED"
                    }}
                    {{ plan.premiumData[index]?.premium ?? 0 }}
                  </td>
                </tr>
                <tr class="total_table">
                  <th>Total Premium</th>
                  <td *ngFor="let plan of selectedPlans; let index = index">
                    {{
                      selectedPlanDetails[index]?.planType === "International"
                        ? "USD"
                        : "AED"
                    }}
                    {{ plan.premiumTotal ?? 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
