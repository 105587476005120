import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { AuthenticatedUser } from '../../models/authenticated-user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmationComponent } from '../../../standalone/logout-confirmation/logout-confirmation.component';
import { ChangePasswordComponent } from '../../../modules/auth/components/change-password/change-password.component';
import { NotificationService } from '../../../core/services/notification.service';
import { Notification } from '../../models/notification.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, OnDestroy {
  unsub$ = new Subject<void>();

  /**
   * To save the authenticated user.
   * @type {AuthenticatedUser}
   */
  user: AuthenticatedUser = null;

  notifications: Notification[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.user$.pipe(takeUntil(this.unsub$)).subscribe((user) => {
      this.user = user;
      this.getNotifications(this.user?._id);
    });

    this.notificationService.notifications$
      .pipe(takeUntil(this.unsub$))
      .subscribe((notifications) => {
        this.notifications = notifications;
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout() {
    this.modalService.open(LogoutConfirmationComponent, { centered: true });
  }

  /**
   * Change Password.
   */
  changePassword() {
    this.modalService.open(ChangePasswordComponent, { centered: true });
  }

  getNotifications(userId: string) {
    this.notificationService
      .getNotifications(userId)
      .pipe(takeUntil(this.unsub$))
      .subscribe();
  }

  notificationClick(notification: Notification) {
    this.notificationService
      .markAsViewed(notification._id)
      .pipe(takeUntil(this.unsub$))
      .subscribe();

    if (
      notification?.type === 'premium_edit_assign' ||
      notification?.type === 'premium_edit_assign_agent' ||
      notification?.type === 'premium_edit_assign_agent_coordinator'
    ) {
      this.router.navigate([
        'dashboard',
        'create-quotation',
        'edit',
        4,
        notification.quotationId,
      ]);
    }
  }
}
