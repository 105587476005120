import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { LoggerService } from '../../../core/logger.service';
import { Router } from '@angular/router';
import { Messages } from '../../enums/messages.enum';

@Component({
  selector: 'app-assign-agent',
  templateUrl: './assign-agent.component.html',
  styleUrl: './assign-agent.component.scss',
})
export class AssignAgentComponent {
  @Input() quotationId: string = null;
  @Input() advisorId: string = null;
  agentForm: FormGroup;
  technicalTeam: { _id: string; name: string; email: string }[] = [];
  submitted = false;
  loading = false;
  advisorLoading = false;
  errorMessage: string = null;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private loggerService: LoggerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.agentForm = new FormGroup({
      agentId: new FormControl(null, [Validators.required]),
    });
    this.getTechnicalTeam();
  }

  getTechnicalTeam() {
    this.advisorLoading = true;
    this.userService.getAgents().subscribe((response) => {
      if (response?.status === 'success') {
        this.technicalTeam = response?.data;
        this.advisorLoading = false;
        if (this.advisorId) {
          this.agentForm.get('agentId').setValue(this.advisorId);
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.agentForm.invalid) {
      return;
    }
    this.loading = true;

    const payload = this.agentForm.value;
    payload.quotationId = this.quotationId;
    this.userService.assignToAgent(payload).subscribe({
      next: (response) => {
        if (response?.status === 'success') {
          this.loggerService.successMessage$.next(response?.message);
          this.router.navigate(['quotations']);
          this.activeModal.close();
        } else {
          this.errorMessage = response?.message ?? Messages.SomethingWentWrong;
          this.loading = false;
        }
      },
      error: (error) => {
        this.errorMessage = error;
        this.loading = false;
      },
    });
  }
}
