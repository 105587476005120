<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">{{ quotation?.quotationId }} - Activities</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <!-- <p>Are you sure, You want to Logout from the current session ?</p> -->
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <app-datatable
              [headers]="headers"
              [filter]="false"
              [export]="false"
              [loadingIndicator]="loadingIndicator"
              [filterApplied]="filterApplied"
              [rows]="rows"
              [totalItems]="totalCount"
              [limit]="limit"
              [sorts]="[{ prop: 'created_at', dir: 'asc' }]"
              (dtOnPage)="dtOnPage($event)"
              (dtOnSort)="dtOnSort($event)"
              (dtOnLimit)="dtOnLimit($event)"
              (dtOnSearch)="dtOnSearch($event)"
            ></app-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
