import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  // Emit the success messages
  successMessage$ = new Subject<string>();

  // Emit the error messages
  errorMessage$ = new Subject<string>();

  /**
   * @method  log
   * @description To console a message
   * @param msg The message should be displayed in console
   */
  log(msg: string) {
    console.log(msg);
  }

  /**
   * @method error
   * @description To console a message as an error
   * @param msg The message should be displayed in console
   */
  error(msg: string) {
    console.error(msg);
  }


}
