import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-alert',
  templateUrl: './warning-alert.component.html',
  styleUrl: './warning-alert.component.scss',
})
export class WarningAlertComponent {
  @Input() title: string;
  @Input() body: string;
  constructor(public activeModal: NgbActiveModal) {}
}
