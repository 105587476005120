<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <p>{{ body }}</p>
  </div>
  <div class="modal-footer border-none">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.close({ status: 'confirmed' })"
    >
      OK
    </button>
  </div>
</div>
