<div class="modal-wrap">
  <div class="modal-header border-none">
    <h5 class="modal-title">Confirmation</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      Are you sure want to send Plan Suspension Notification to Selected
      Customers ?
    </p>
  </div>
  <div class="modal-footer border-none">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="sendMail()"
      [disabled]="sending"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="sending"
      ></span>

      Send
    </button>
  </div>
</div>
